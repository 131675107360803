import { ClassProvider, Injectable } from "@angular/core";
import { Observable, Subscription, debounceTime, interval, lastValueFrom, map, of, skipWhile, switchMap, tap, throwError } from "rxjs";
import { HttpService } from "./http.service";
import { JWTService } from "./jwt.service";
import Bugsnag from "@bugsnag/js";
import { CacheService } from "./cache.service";
import { GAService } from "./ga.service";
import { Constants } from "src/constants";
import { WindowService } from "./window.service";
import { ActivityMonitorService } from "./activity-monitor.service";
import { NavigationService } from "./navigation.service";
import { notifyAndLeaveBreadcrumb } from "../utils/logging";
import { USER_SESSION } from "@shared/constants";
import { LocationService } from "./location.service";
import { AnalyticsService, METRIC } from "./analytics.service";

const { SESSION_EXPIRY, KEEP_ALIVE_INTERVAL } = USER_SESSION.PATIENT;
const LAST_ACTIVITY_KEY = "lastActivity";

interface I_CachedActivity {
  timestamp: number;
}

@Injectable({
  providedIn: "root",
})
export abstract class SessionService {
  constructor(
    protected _httpService: HttpService,
    protected _jwtService: JWTService,
    protected _cacheService: CacheService,
    protected _gaService: GAService,
    protected _windowService: WindowService,
    protected _activityMonitorService: ActivityMonitorService,
    protected _navigationService: NavigationService,
    protected _locationService: LocationService,
    protected _analyticsService: AnalyticsService
  ) {
    this._jwtService.onSessionIdChanged.subscribe(() => {
      this._setSessionId();
    });
  }

  public get isPasswordRequired(): boolean {
    return false;
  }

  public abstract init(): Promise<void>;

  public async clear(): Promise<void> {
    try {
      await lastValueFrom(
        this._httpService.send(`/sessions`, {
          method: "DELETE",
        })
      );
    } catch (error) {
      console.error("error clearing session", error);
      Bugsnag.notify(error);
    }
    this._jwtService.delete();
  }

  public onPageUnload(): void {}

  public async logout(signoutPath = "/signout"): Promise<void> {
    this._cacheService.deleteSession(Constants.PATIENT_ACTIONS_SESSION_STORAGE_KEY);
    Bugsnag.leaveBreadcrumb("Sign out");
    this._gaService.action("signout");
    this._cacheService.clearSession();
    await this.clear();
    this._windowService.href = signoutPath;
  }

  public startKeepAlive(): void {}
  public onPasswordValidated(): void {}
  public extendSession(_password: string): Observable<void> {
    return of();
  }

  protected _setSessionId(): void {}
}

@Injectable()
export class GenericSessionService extends SessionService {
  private _intervalSubscription: Subscription;
  private _activitySubscription: Subscription;
  private _focusSubscription: Subscription;
  private _invalidJWTSubscription: Subscription;
  private _lastActivity: number;

  public get isPasswordRequired(): boolean {
    if (!this._jwtService.isLoggedIn()) {
      // These should not need clearing but just in case
      this._clearLastActivity();
      this._clearActivitySubscriptions();

      return false;
    }

    return this._isPasswordRequired(this._lastActivity);
  }

  private _restoreLastActivity(): void {
    const activityCache = this._cacheService.getJson<I_CachedActivity>(LAST_ACTIVITY_KEY);

    if (activityCache) {
      this._lastActivity = activityCache.timestamp;
    }
  }

  public async init(): Promise<void> {
    this._lastActivityInit();

    try {
      const jwt = await lastValueFrom(this._getSession());

      if (!jwt) return;

      const existingJWT = this._jwtService.getJWTString();

      if (!!existingJWT) {
        if (existingJWT !== jwt) Bugsnag.notify(new Error("JWT stored in local storage does not match the one returned from the server"));

        return;
      }

      // TODO: once we're happy that this is working (i.e. no Bugsnag errors are being reported) we can set the token in memory and
      //       prevent it being stored the local storage
    } catch (error) {
      Bugsnag.notify(error);
    }
  }

  private _lastActivityInit(): void {
    this._restoreLastActivity();

    if (this._jwtService.isLoggedIn()) {
      if (!this._lastActivity) {
        this._lastActivity = this._activityMonitorService.lastActivity;
      }

      if (this.isPasswordRequired) {
        this._navigateToReEnterPassword();
      }
    }
  }

  private _getSession(): Observable<string | null> {
    return this._httpService
      .send(`/sessions`, {
        method: "GET",
      })
      .pipe(map((response: { jwt: string } | null) => response?.jwt || null));
  }

  private _setSession(): Observable<string | any[] | null> {
    return this._httpService.send(`/sessions`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this._jwtService.getJWTString()}`,
      },
    });
  }

  protected _setSessionId(): void {
    try {
      this._setSession().subscribe();
    } catch (error) {
      Bugsnag.notify(error);
    }
  }

  public clear(): Promise<void> {
    this._clearLastActivity();

    return super.clear();
  }

  public startKeepAlive(): void {
    if (!this._jwtService.isLoggedIn()) {
      this._cacheService.delete(LAST_ACTIVITY_KEY);

      // We only want to keep the session alive for logged in patients because other mechanisms are in place for other access levels
      return;
    }

    this._clearActivitySubscriptions();
    this._restoreLastActivity();

    if (this._isPasswordRequired(this._lastActivity)) {
      this._navigateToReEnterPassword();

      return;
    }

    this._intervalSubscription = interval(KEEP_ALIVE_INTERVAL).subscribe(() => {
      if (Date.now() - this._lastActivity > KEEP_ALIVE_INTERVAL) {
        // No activity since last keep alive
        if (this._isPasswordRequired(this._lastActivity)) {
          this._navigateToReEnterPassword();
        }

        return;
      }

      this._handleActivity();
    });

    this._activitySubscription = this._activityMonitorService.activity.pipe(debounceTime(1000)).subscribe((activity) => {
      if (this._isPasswordRequired(this._lastActivity)) {
        this._navigateToReEnterPassword();

        return;
      }

      this._lastActivity = activity.timestamp;
      this._cacheLastActivity();
    });

    this._focusSubscription = this._activityMonitorService.focus
      .pipe(skipWhile((focus) => focus)) // Ignore the default value
      .subscribe(() => {
        if (this._isPasswordRequired(this._lastActivity)) {
          this._navigateToReEnterPassword();
        } else {
          this._lastActivity = Date.now();
        }

        this._handleActivity();
      });

    this._invalidJWTSubscription = this._jwtService.onInvalidJWT.subscribe(() => {
      this._clearLastActivity();
    });
  }

  private _clearLastActivity(): void {
    this._cacheService.delete(LAST_ACTIVITY_KEY);
    this._activityMonitorService.clear();
  }

  private _cacheLastActivity(): void {
    const jwt = this._jwtService.getJWT();

    if (!jwt) {
      return;
    }

    this._cacheService.setJson<I_CachedActivity>(
      LAST_ACTIVITY_KEY,
      {
        timestamp: this._lastActivity,
      },
      this._jwtService.getJWT().exp * 1000
    );
  }

  public onPasswordValidated(): void {
    this._cacheService.delete(LAST_ACTIVITY_KEY);
    this._lastActivity = Date.now();
    this._cacheLastActivity();
    this._locationService.href = "/";
  }

  public extendSession(password: string): Observable<void> {
    Bugsnag.leaveBreadcrumb("extending session with password");

    return this._extendSession(Date.now(), password).pipe(
      tap({
        next: () => {
          this._lastActivity = Date.now();
          this.startKeepAlive();
        },
        error: (error) => {
          Bugsnag.notify(error);
        },
      })
    );
  }

  private _clearActivitySubscriptions(): void {
    const subscriptions = ["_intervalSubscription", "_activitySubscription", "_focusSubscription", "_invalidJWTSubscription"];

    subscriptions.forEach((subscription) => {
      if (subscription in this && this[subscription] instanceof Subscription) {
        this[subscription].unsubscribe();
      }
    });
  }

  private _handleActivity(): void {
    if (this._isPasswordRequired(this._lastActivity)) {
      this._navigateToReEnterPassword();

      return;
    }

    this._lastActivity = Date.now();

    this._extendSession(this._lastActivity).subscribe({
      error: () => {
        this._navigateToReEnterPassword();
      },
    });
  }

  private _extendSession(lastActivity: number, password?: string): Observable<void> {
    let args = `last_activity:${(lastActivity / 1000).toFixed(0)}`;

    if (password) {
      args += `, password:"${password}"`;
    }

    return this._httpService.mutation("extendSession", `{ extendSession(${args}) }`).pipe(
      switchMap((response) => {
        if (response.errors?.length) {
          return throwError(() => new Error(response.errors[0].message));
        }

        return of(response);
      })
    );
  }

  private _isPasswordRequired(lastActivity: number): boolean {
    return Date.now() - lastActivity > SESSION_EXPIRY - KEEP_ALIVE_INTERVAL;
  }

  private _navigateToReEnterPassword(): void {
    this._clearActivitySubscriptions();

    this._navigationService.navigate(Constants.ROUTES.LOGIN_RE_ENTER_PASSWORD.path);

    this._analyticsService.track(new METRIC.SessionExpired(this._lastActivity));
  }
}

const TOKEN_STORAGE_KEY = "token";

@Injectable()
export class PipSessionService extends SessionService {
  public init(): Promise<void> {
    const token = this._cacheService.getSession(TOKEN_STORAGE_KEY);

    if (token) {
      this._cacheService.deleteSession(TOKEN_STORAGE_KEY);

      try {
        this._jwtService.setToken(token);
      } catch (error) {
        notifyAndLeaveBreadcrumb("Failed to restore JWT token", error);

        this._windowService.href = "/pair";

        return Promise.reject(error);
      }
    }

    return Promise.resolve();
  }

  public onPageUnload(): void {
    this._cacheService.setSession(TOKEN_STORAGE_KEY, this._jwtService.getJWTString());
  }
}

export function sessionServiceFactory(isPip: boolean): ClassProvider {
  return {
    provide: SessionService,
    useClass: isPip ? PipSessionService : GenericSessionService,
    multi: false,
  };
}
