import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import Bugsnag from "@bugsnag/js";
import { Observable, tap } from "rxjs";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          Bugsnag.leaveBreadcrumb("HttpError", { url: error.url, status: error.status, message: error.message, error }); // Leave details about the error in the breadcrumb
          Bugsnag.notify(new Error(error.status === 503 ? "HttpTimeoutError" : "HttpError"));
        },
      })
    );
  }
}
