import { Injectable } from "@angular/core";
import { CacheService } from "./cache.service";
import { environment } from "src/environments/environment";
import { E_GlobalDomains } from "@shared/constants";

const HOSTNAME_STORAGE_KEY = "hostname";
const ACTUAL_HOSTNAME_STORAGE_KEY = "actual-hostname";
const { STAGE, HOST_ZONE } = environment;

@Injectable({
  providedIn: "root",
})
export class LocationService {
  private _hostname;
  private _actualHostname;

  constructor(private _cacheService: CacheService) {
    try {
      this._hostname = this._cacheService.getSession(HOSTNAME_STORAGE_KEY);
      this._actualHostname = this._cacheService.getSession(ACTUAL_HOSTNAME_STORAGE_KEY);
    } catch {}
  }

  public get hostname(): string {
    return this._hostname || window.location.hostname;
  }

  public set hostname(hostname: string) {
    this._hostname = hostname;

    this._cacheService.setSession(HOSTNAME_STORAGE_KEY, hostname);
  }

  public set actualHostname(hostname: string) {
    this._actualHostname = hostname;

    this._cacheService.setSession(ACTUAL_HOSTNAME_STORAGE_KEY, hostname);
  }

  /**
   * Gets the actual hostname of the current window. Use hostname to get the relative hostname (used to make global domains work)
   */
  public get actualHostname(): string {
    return this._actualHostname || window.location.hostname;
  }

  public get pathname(): string {
    return window.location.pathname;
  }

  public set href(href: string) {
    window.location.href = href;
  }

  public get isBookingDomain(): boolean {
    return !!this.actualHostname?.match(/^booking\.(dentr|(sandbox\.)?portal\.dental)/);
  }

  public get isPairDomain(): boolean {
    const domains = [
      `beta.${this._stage}.${E_GlobalDomains.SANDBOX}`,
      `beta.${E_GlobalDomains.SANDBOX}`,
      `beta.${E_GlobalDomains.PRODUCTION}`,
      `pair.${this._stage}.${E_GlobalDomains.SANDBOX}`,
      `pair.${E_GlobalDomains.SANDBOX}`,
      `pair.${E_GlobalDomains.PRODUCTION}`,
    ];

    if (domains.includes(window.location.hostname)) return true;

    return !!window.location.hostname.match(/^pair\.(dentr|(sandbox\.)?portal\.dental)/);
  }

  public get isNewPatientDomain(): boolean {
    if (this.actualHostname?.endsWith(E_GlobalDomains.PRODUCTION)) return true;

    if (!this.actualHostname?.endsWith(HOST_ZONE) && ["development", "production"].includes(this._stage)) {
      // Custom domain which is not mapped to a stage. This will handle portal-test.net but Bupa's custom domains will be unaffected
      return true;
    }

    return false;
  }

  private get _stage(): string {
    return STAGE;
  }

  public get isPreferencesDomain(): boolean {
    const regex = new RegExp(`^preferences\.(${this._stage}\.)?(${E_GlobalDomains.SANDBOX}|${E_GlobalDomains.PRODUCTION})$`);

    return regex.test(this.actualHostname);
  }

  public get isRootDomain(): boolean {
    return (
      this.actualHostname === E_GlobalDomains.PRODUCTION ||
      this.actualHostname === E_GlobalDomains.SANDBOX ||
      this.actualHostname === `${this._stage}.${E_GlobalDomains.SANDBOX}`
    );
  }

  public get search(): Record<string, string> {
    const params = new URLSearchParams(window.location.search) as any;

    return Object.fromEntries([...params]) as Record<string, string>;
  }
}
